'use client'

import { useEffect } from "react"
import { emitEvent } from "./EventListener"
import Intercom from '@intercom/messenger-js-sdk';
import { show } from '@intercom/messenger-js-sdk';

export default function Plugins(props) {

  let jsCodeLinks = []
  let jsCode = []

  props.config.plugins?.js?.map(js => {
    if (js.link) jsCodeLinks.push(js)
    if (js.code) jsCode.push(js)
  })

  useEffect(() => {
    window.hrxConfig = {
      ga_id: props.config.creds?.ga_id
    }
    emitEvent("config_loaded", props.config)
    const intercomInstance = Intercom({
      app_id: 'vhn94oyb',
      // user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      // name: user.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      // email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      // created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });

    // Show the Intercom popup by default
    show();
  })

  return <>
    { jsCode.map((js,index) => (
      <script key={index} id={"hrx_js_code_" + js.name.replace(" ", "_")} dangerouslySetInnerHTML={{ __html: js.code }}></script>
    ))}
    { jsCodeLinks.map((js,index) => (
      <script key={index} id={"hrx_js_link_" + js.name.replace(" ", "_")} src={js.link} async></script>
    ))}

  </>

}
